<!--
 * @Author: lina
 * @LastEditors: lina
 * @Date: 2024-08-28
 * @LastEditTime: 2024-08-28
 * @Descripttion: 【内容】备案管理
-->
<style lang="scss" scoped>
.textbook {
    @include innerPage(40px 34px 48px 15px);
    @include pageTitle(0);
    @include defalutTable(calc(100% - 104px), 20px);
    @include pageFoot;

    .page-title {
        flex-wrap: wrap;

        .search-form {
            width: 78.67%;
            min-width: 1000px;

            &-wrapper {
                width: 100%;
                margin-top: 16px;
            }
        }

        .el-form-item {
            width: 16%;
            max-width: 190px;
            min-width: 164px;

            &:last-child {
                flex-grow: 1;
                max-width: 340px;
            }
        }
    }
}

.dialog-container {
    width: calc(100% + 20px);
    max-height: 380px;
    box-sizing: border-box;
    padding: 10px 20px 0 18px;
    margin: 24px 0 48px;
    overflow: hidden;
    overflow-y: auto;

    .tips {
        color: #e58181;
        height: 24px;
        position: absolute;
        top: 24px;
        right: 120px;
        font-size: 14px;
        @include flexBox;

        .iconfont {
            font-size: 20px;
            margin-right: 4px;
        }
    }

    .reason {
        color: #e58181;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 20px;
        @include flexBox;
        align-items: flex-start;

        span {
            flex-shrink: 0;
        }
    }

    .el-form-item:last-child {
        margin-bottom: 0;
    }
}
</style>

<template>
    <section class="textbook">
        <div class="page-title">
            <h3>备课管理</h3>
            <el-button type="custom_primary" size="medium" @click="addData()">
                添加备课 +
            </el-button>
        </div>
        <div class="table-box">
            <div class="table-inner" ref="tableInner">
                <el-table :data="tableData" height="100%">
                    <el-table-column
                        align="center"
                        prop="tetea_id"
                        label="备课ID"
                        min-width="6.75%"
                    />
                    <el-table-column
                        align="center"
                        prop="sccou_cha_title"
                        label="备课章节"
                        min-width="6.75%"
                    />
                    <el-table-column
                        align="center"
                        prop="create_time"
                        label="备课时间"
                        min-width="6.75%"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.create_time }}
                        </template>
                    </el-table-column>
                    <!--                    <el-table-column align="center" label="启用/禁用" min-width="8.41%">
                        <template slot-scope="scope">
                            <el-switch class="text-inner" v-model="scope.row.sccou_sho_status" :active-value="10"
                                       :inactive-value="20" inactive-text="启用" active-text="禁用"
                                       v-if="scope.row.sccou_sharestatus == 10 || scope.row.sccou_sharestatus == 5"
                                       @change="changeStatus(scope.row.sccou_id, scope.row.sccou_sho_status, scope.$index)"/>
                            <p v-if="scope.row.sccou_sharestatus != 10 && scope.row.sccou_sharestatus != 5">-</p>
                        </template>
                    </el-table-column>-->
                    <el-table-column
                        align="center"
                        label="操作"
                        min-width="17.13%"
                    >
                        <template slot-scope="scope">
                            <el-button
                                type="custom_success"
                                size="mini"
                                plain
                                @click="
                                    $router.push({
                                        name: 'CONTENT_TEXTBOOK_PREPARE',
                                        params: { teteaId: scope.row.tetea_id },
                                    })
                                "
                            >
                                编辑
                            </el-button>
                            <el-button
                                type="custom_warning"
                                size="mini"
                                plain
                                @click="handleDestroy(scope.row.tetea_id)"
                            >
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="table-foot custom-foot">
                <customPagination
                    :current="pagination.pageIndex"
                    :total="pagination.total"
                    @pageChange="flippingPage"
                />
            </div>
        </div>
    </section>
</template>

<script>
import {
    $textbookList,
    $textbookAdd,
    $textbookEdit,
    $textbookStatusChange,
    $textbookShartet,
    teateachingList,
    addTeaTeaching,
    deleteTeaTeaching,
} from "@api/content";
import { mapState } from "vuex";
import customPagination from "@comp/customPagination";

export default {
    name: "content_teateaching",
    components: { customPagination },
    computed: {
        ...mapState("common", ["gradeList", "subjectList"]),
    },
    data() {
        return {
            searchForm: {}, // 搜索参数
            tableData: [], // 表格参数
            /* 表格分页参数 */
            pagination: {
                pageIndex: 1,
                total: 0, //总数
                current_page: 1, //当前页默认第一页
                size: 10, //每页数量
                sizes: [10, 15, 20, 25, 30], //分页模式
            },
            textbookDialog: false, // 教材编辑显示弹窗
            textbookForm: {}, // 教材编辑表单
            dataAudit: {}, // 教材审核内容
            btnload: false,
            /* 表单校验规则 */
            rules: {
                sccou_grade: [
                    {
                        required: true,
                        type: "number",
                        message: "请选择年级",
                        trigger: "change",
                    },
                ],
                semester: [
                    {
                        required: true,
                        type: "number",
                        message: "请选择学期",
                        trigger: "change",
                    },
                ],
                sysub_id: [
                    {
                        required: true,
                        type: "number",
                        message: "请选择教材科目",
                        trigger: "change",
                    },
                ],
                syedi_title: [
                    {
                        required: true,
                        message: "请输入教材版本名称",
                        trigger: "blur",
                    },
                ],
                syedi_version: [
                    {
                        required: true,
                        message: "请输入教材版号",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        this.getData();
    },
    methods: {
        /** 获取教材数据 */
        async getData() {
            let params = {
                sccou_cha_id: this.$route.params.id,
                pageindex: this.pagination.pageIndex,
            };
            let { data: res } = await teateachingList(params);
            this.pagination.total = res.allcount;
            this.tableData = res.data;
            this.$forceUpdate();
        },
        /** 添加备课 */
        async addData() {
            let params = { sccou_cha_id: this.$route.params.id };
            let res = await addTeaTeaching(params);
            if (res) {
                if (res.status == 200) {
                    this.$router.push({
                        name: "CONTENT_TEXTBOOK_PREPARE",
                        params: { teteaId: res.data.tetea_id },
                    });
                }else{
                    this.$message.error("添加失败");
                }
            }
        },
        /** 编辑教材 */
        async editData() {
            let params = { ...this.textbookForm };
            let res = await $textbookEdit(params);
            this.btnload = false;
            if (res) {
                this.hideDialog();
                this.$message.success("编辑成功");
                this.getData();
            }
        },
        /**
         * 修改教材状态
         * @param {number} id 教材id
         * @param {number} type 教材状态
         */
        async changeStatus(id, type, index) {
            let res = await $textbookStatusChange(id, type);
            if (res) {
                this.$message.success("教材状态修改成功");
                this.getData();
            } else {
                this.tableData[index].sccou_sho_status =
                    (type == 10 && 20) || 10;
            }
        },
        /** 共享教材 */
        shareData(id) {
            let valid_suc = false;
            let params;
            /* 判断共享时，是否正在编辑或添加教材 */
            if (this.textbookDialog) {
                this.$refs.textbookForm.validate((valid) => {
                    if (valid) {
                        valid_suc = true;
                        params = { ...this.textbookForm, saveType: 2 };
                    }
                });
            }
            /* 共享时，正在添加或编辑教材但表单校验未通过 */
            if (this.textbookDialog && !valid_suc) return;
            /* 共享时，正在添加或编辑教材且表单校验通过 或 教材未处在编辑或添加状态 */
            if (!this.textbookDialog || (this.textbookDialog && valid_suc)) {
                this.$msgbox({
                    title: "提示",
                    message:
                        "您的教材共享通过之后，该教材章节管理权限将进入平台，请谨慎操作！",
                    type: "info",
                    showClose: false,
                    showCancelButton: true,
                    cancelButtonClass: "el-button--custom_info",
                    confirmButtonClass: "el-button--custom_primary",
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                })
                    .then(async () => {
                        // 操作共享请求
                        let res;
                        /* 添加保存并共享教材 */
                        if (!id) {
                            res = await $textbookAdd(params);
                        } else if (this.textbookDialog) {
                        /* 编辑保存并共享教材 */
                            res = await $textbookEdit(params);
                        } else {
                        /* 直接共享教材 */
                            res = await $textbookShartet(id);
                        }
                        if (res) {
                            this.$message({
                                type: "success",
                                duration: 1500,
                                message: "教材已共享！",
                            });
                            this.getData();
                            if (this.textbookDialog) this.hideDialog();
                        }
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            duration: 1500,
                            message: "操作已取消",
                        });
                    });
            }
        },
        /** 搜索 */
        searchData() {
            this.tableData = [];
            this.pagination.pageIndex = 1;
            this.getData();
        },
        /** 翻页 */
        flippingPage(val) {
            this.pagination.pageIndex = val;
            this.getData();
        },
        /** 开启弹窗 */
        showDialog(data) {
            this.textbookDialog = true;
            if (data.sccou_id) {
                let keys = [
                    "sccou_id",
                    "sccou_grade",
                    "semester",
                    "syedi_title",
                    "syedi_version",
                    "sysub_id",
                ];
                let form = {};
                keys.map((key) => {
                    form[key] = data[key];
                });
                this.dataAudit = {
                    sccou_sharestatus: data.sccou_sharestatus,
                    sccou_reason: data.sccou_reason,
                };
                this.textbookForm = { ...form };
            }
        },
        /** 关闭弹窗 */
        hideDialog() {
            this.textbookDialog = false;
            this.dataAudit = {};
            this.resetForm("textbookForm");
            this.textbookForm = {};
        },
        /** 重置表单 */
        resetForm(ref) {
            this.$refs[ref].resetFields();
        },

        /** 删除 */
        async handleDestroy(id) {
            this.$confirm("此操作将删除该条记录, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                /** 确认触发 */
                .then(async () => {
                    const res = await deleteTeaTeaching(id);
                    // 判断当前页数据是否仅剩1条
                    if (this.tableData.length == 1) {
                        this.pagination = {
                            ...this.pagination,
                            page:
                                (this.pagination.pageindex > 1 &&
                                    this.pagination.pageindex - 1) ||
                                1, // 判断当前页是否为第一页，不为第一页则减少一页
                            current_page:
                                (this.pagination.current_page > 1 &&
                                    this.pagination.current_page - 1) ||
                                1, // 判断当前页是否为第一页，不为第一页则减少一页
                        };
                    }
                    this.$message({
                        type: "success",
                        message: res.msg,
                    });
                    this.infoDrawerVisible = false;
                    this.getData();
                })
                /** 取消触发 */
                .catch(() => {
                    console.log("取消");
                    this.$message({
                        type: "info",
                        message: "已取消删除操作",
                    });
                });
        },
    },
};
</script>
